import { useMerchantPrivateStore } from 'UseMerchantStore'
import { FirebaseDb } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import { useCallback, useMemo } from 'react'
import { MerchantRecentEvent } from '../../functions/src/data/common'

interface MerchantNotificationsPanelProps {
  merchantId: string | undefined
  firebaseDb: FirebaseDb
  user?: User
}

export interface UseNotificationMerchantResult {
  recentEvents: (MerchantRecentEvent & { eventId: string })[] | undefined
  historyEvents: (MerchantRecentEvent & { eventId: string })[] | undefined
  permissions: string
  discardNotification: (eventId: string) => void
}

const useNotificationMerchant = ({
  merchantId,
  firebaseDb,
  user,
}: MerchantNotificationsPanelProps): UseNotificationMerchantResult => {
  const merchantPrivateStoreResult = useMerchantPrivateStore(merchantId, user, firebaseDb)
  const merchantAdminPermissions = merchantPrivateStoreResult.type

  const recentEventsRef = useMemo(
    () =>
      merchantAdminPermissions === 'SUCCESS' ?
        merchantPrivateStoreResult?.merchantRef?.childFromKey('recentEvents')
      : undefined,
    [merchantAdminPermissions, merchantPrivateStoreResult],
  )
  const historyEventsRef = useMemo(
    () =>
      merchantAdminPermissions === 'SUCCESS' ?
        merchantPrivateStoreResult?.merchantRef?.childFromKey('historyEvents')
      : undefined,
    [merchantAdminPermissions, merchantPrivateStoreResult],
  )

  const notifications =
    merchantAdminPermissions === 'SUCCESS' ?
      Object.entries(merchantPrivateStoreResult.merchantData.recentEvents ?? {})
        .map(([eventId, eventData]) => ({ ...eventData, eventId }))
        ?.orderByDesc((it) => it.timestamp)
        .slice(0, 10)
    : undefined

  const historyNotifications =
    merchantAdminPermissions === 'SUCCESS' ?
      Object.entries(merchantPrivateStoreResult.merchantData.historyEvents ?? {})
        .map(([eventId, eventData]) => ({ ...eventData, eventId }))
        ?.orderByDesc((it) => it.timestamp)
    : undefined

  const discardNotification = useCallback(
    async (eventId: string) => {
      if (!recentEventsRef || !historyEventsRef) {
        process.env.NODE_ENV === 'development' &&
          console.error(`${!recentEventsRef ? 'recentEventsRef' : 'historyEvents'} is undefined`)
        return
      }

      const discardEvent = notifications?.find((it) => it.eventId === eventId)
      if (discardEvent) {
        const { eventId, ...event } = discardEvent
        await Promise.all([
          historyEventsRef.child(eventId).set(event),
          recentEventsRef.child(eventId).remove(),
        ])
      }
    },
    [historyEventsRef, notifications, recentEventsRef],
  )

  //todo handle more notifications for customer
  return {
    recentEvents: notifications,
    historyEvents: historyNotifications,
    permissions: merchantAdminPermissions,
    discardNotification,
  }
}

export default useNotificationMerchant
