import MbStickyFooter from 'components/footer/MbStickyFooter'
import Header from 'components/header'
import { LayoutProps } from './types'

// const Wrapper = tw.div`bg-white text-emerald-400`

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className='relative flex h-svh flex-col'>
      <Header className='flex-shrink-0' />
      <div className='flex-1 overflow-y-auto'>{children}</div>
      <MbStickyFooter className='flex-shrink-0' />
    </div>
  )
}

export default Layout
