import { Button } from '@chakra-ui/react'
import clsx from 'clsx'
import { FirebaseDb } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import useNotificationMerchant, {
  UseNotificationMerchantResult,
} from 'hooks/useNotificationMerchant'
import { ReactComponent as UserCircleSVG } from 'icons/account_circle.svg'
import { ReactComponent as QRCodeSVG } from 'icons/qr_code.svg'
import React, { useMemo, useRef, useState } from 'react'
import { FaXmark } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'

interface TextIcon {
  icon: React.ReactNode
  message: string | React.ReactNode
}
interface INotificationPanelProps {
  className?: string
  children?: React.ReactNode
  firebaseDb: FirebaseDb
  merchantId: string | undefined
  user?: User
  isHistory?: boolean
}

const NotificationPanel: React.FC<INotificationPanelProps> = (props) => {
  const { recentEvents, historyEvents, discardNotification } = useNotificationMerchant({
    firebaseDb: props.firebaseDb,
    merchantId: props.merchantId,
    user: props.user,
  })

  const revertTitle = (text: string): TextIcon => {
    if (text.includes('Card created for')) {
      return {
        icon: <UserCircleSVG className='h-8 w-8' />,
        message: <span>{text}</span>,
      }
    } else if (text.includes('scanned your store qr-code')) {
      return {
        icon: <QRCodeSVG className='h-8 w-8' />,
        message: <span>{text}</span>,
      }
    } else {
      return {
        icon: <></>,
        message: text,
      }
    }
  }

  // if (type === 'PENDING') {
  //   return <NotificationSkeleton />
  // }
  return (
    <div
      className={clsx(
        props.className,
        `no-scrollbar mb: w-full overflow-y-auto py-2 pb-16 pt-4 lg:rounded lg:bg-white lg:p-2 lg:pb-2
        lg:shadow-md`,
      )}>
      <h2 className='mb-4 pt-0 text-xl font-semibold lg:bg-white lg:p-2'>
        {props.isHistory ? 'My History' : 'Notifications'}
      </h2>
      <div className='space-y-2'>
        {(props.isHistory ? historyEvents : recentEvents)?.map((it) => {
          const { icon, message } = revertTitle(it.title)
          return (
            <NotificationItem
              {...it}
              key={it.timestamp}
              icon={icon}
              message={message}
              firebaseDb={props.firebaseDb}
              isHistory={props.isHistory}
              discardNotification={discardNotification}
            />
          )
        })}
      </div>
    </div>
  )
}

const SWIPE_THRESHOLD_PERCENTAGE = 60 // swipe percent to discard

const NotificationItem = ({
  eventId,
  icon,
  message,
  timestamp,
  title,
  link,
  firebaseDb,
  isHistory,
  discardNotification,
}: {
  eventId: string
  icon: React.ReactNode
  message: React.ReactNode
  timestamp: number
  title: string
  link: string
  firebaseDb: FirebaseDb
  isHistory?: boolean
  discardNotification: UseNotificationMerchantResult['discardNotification']
}) => {
  const itemRef = useRef<HTMLDivElement>(null)
  const [swipePercentage, setSwipePercentage] = useState(0)

  const handlers = useSwipeable(
    useMemo(
      () =>
        isHistory ?
          {}
        : {
            onSwiping: (eventData) => {
              if (eventData.dir === 'Left') {
                const width = itemRef.current?.offsetWidth || 0
                const newSwipePercentage = Math.min((eventData.absX / width) * 100, 100)
                setSwipePercentage(newSwipePercentage)
              }
            },
            onSwipedLeft: () => {
              if (swipePercentage >= SWIPE_THRESHOLD_PERCENTAGE) {
                setSwipePercentage(100)
                discardNotification(eventId)
              } else {
                setSwipePercentage(0)
              }
            },
          },
      [discardNotification, eventId, isHistory, swipePercentage],
    ),
  )

  return (
    <div ref={itemRef}>
      <div className='relative overflow-hidden rounded-md border-b-2 border-gray-200 p-2 pb-4'>
        <div
          {...handlers}
          className='flex flex-col gap-y-2'
          style={{
            transform: `translateX(-${swipePercentage}%)`,
          }}>
          <div className='flex items-start gap-x-2'>
            <div>{icon}</div>
            <div className='text-slate-600'>
              <div className='flex flex-col items-start gap-x-2 rounded-md'>
                <div className='flex flex-col items-start justify-start gap-2 text-sm'>
                  {message}
                </div>
                <div style={{ fontSize: '0.8em' }}>{new Date(timestamp).toLocaleString()}</div>{' '}
              </div>
            </div>
            {!isHistory && (
              <FaXmark
                className='ml-auto hidden h-7 w-7 cursor-pointer lg:block'
                onClick={() => {
                  discardNotification(eventId)
                }}
              />
            )}
          </div>
          {title && (
            <div className='text-center'>
              <RewardButton
                url={link}
                firebase={firebaseDb}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NotificationPanel

export const RewardButton: React.FC<{ url: string; firebase: FirebaseDb }> = ({
  url,
  firebase,
}) => {
  const navigate = useNavigate()

  return (
    <Button
      className='rounded-lg bg-[#bf997f] px-3 py-1 text-csm text-white transition-colors duration-150
        hover:cursor-pointer hover:brightness-110'
      onClick={() => navigate(url)}>
      Reward
    </Button>
  )
}
