import { Spinner } from '@chakra-ui/react'
import { useMerchantCreateStore, useUserTeamsStore } from 'UseMerchantStore'
import { useWindowDimensions } from 'UseWindowDimensions'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { useQueryString } from 'components/QueryString'
import { ButtonIcon } from 'components/button/ButtonIcon'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import { cn } from 'components/common/utils/tailwindUtils'
import CustomInput from 'components/input/CustomInput'
import { TwLableForm } from 'components/modules/twComponent/twCommon.module'
import { PATH } from 'constants/routes'
import { updateProfile } from 'firebase/auth'
import { TwTitle } from 'pages/Home/twHome.module'
import { TwCard } from 'pages/SignIn/twSignIn.module'
import { useCallback, useEffect, useState } from 'react'
import { Control, FieldErrors, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'

interface MerchantFormProps {
  name: string
  businessName: string
}

const MerchantCreate: React.FC = () => {
  const navigate = useNavigate()
  const { auth, firebaseDb } = useFirebase(firebaseConfig)
  const { user } = useUserNullable({ auth: auth })
  const { createMerchant, isCreating } = useMerchantCreateStore(firebaseDb)
  const userTeams = useUserTeamsStore(user, firebaseDb)
  const [redirectLocation] = useQueryString('redirect', '/')
  const windowDimensions = useWindowDimensions()
  const [showSlides, setShowSlides] = useState(false)
  const [slideFinished, setSlideFinished] = useState(false)
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<MerchantFormProps>()

  // Todo create merchant for user
  const onSubmit = useCallback(
    async (data: MerchantFormProps) => {
      if (!user) return
      try {
        const userId = user?.uid
        const hydratedUser = await auth.hydrateProfileIfRequired(user)

        //update user display name
        await updateProfile(hydratedUser, { displayName: data.name })

        // waiting for merge PR to main branch
        await createMerchant(data.businessName, userId)

        if (windowDimensions.width >= 1024) setShowSlides(true)
      } catch (error) {
        console.error('Error creating merchant:', error)
        reset()
      }
    },
    [auth, createMerchant, reset, user, windowDimensions.width],
  )

  useEffect(() => {
    if (user?.displayName) {
      // entered user name previously in hydrate dialog in useUserNullable
      setValue('name', user.displayName)
    }
  }, [setValue, user])

  useEffect(() => {
    if (userTeams.type === 'SUCCESS' && userTeams.teams?.permissions) {
      const role = userTeams.teams?.permissions
      const merchantId = (role && Object.keys(role)[0]) || ''

      if (
        windowDimensions.width < 1024 ? merchantId
        : isCreating || showSlides ? merchantId && slideFinished
        : merchantId
      ) {
        navigate(PATH.MERCHANT_DASHBOARD.replace(':id', merchantId) ?? redirectLocation)
      }
    }
  }, [
    isCreating,
    navigate,
    redirectLocation,
    showSlides,
    slideFinished,
    userTeams,
    windowDimensions.width,
  ])

  if (showSlides) {
    return <MerchantCreateSlide onFinish={() => setSlideFinished(true)} />
  }

  return (
    <div className='flex min-h-screen items-center justify-center lg:items-start lg:pt-32'>
      {isCreating && windowDimensions.width < 1024 ?
        <MerchantCreateSplash />
      : <MerchantCreateForm
          onSubmit={handleSubmit(onSubmit)}
          errors={errors}
          control={control}
          isCreating={isCreating}
        />
      }
    </div>
  )
}

type MerchantCreateFormProps = {
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined
  errors: FieldErrors<MerchantFormProps>
  control: Control<MerchantFormProps, any>
  isCreating: boolean
}

const MerchantCreateForm: React.FC<MerchantCreateFormProps> = ({
  onSubmit,
  errors,
  control,
  isCreating,
}) => {
  return (
    <TwCard>
      <div className='mb-6 flex items-center justify-center lg:hidden'>
        <Logo height={25} />
      </div>
      <TwTitle className='!mb-4'>Create Account</TwTitle>
      <p className='mb-3 text-center'>
        Let&apos; set up an account, so you can start creating rewards programs
      </p>
      <div className='px-2'>
        <form onSubmit={onSubmit}>
          <div>
            <TwLableForm>NAME</TwLableForm>
            <CustomInput
              label=''
              name='name'
              type='text'
              placeholder='Jiara Martins'
              error={errors.name}
              // onChangeCallback={(value) => handleInputChange('name', value)}
              control={control}
              rules={{ required: 'Name is required' }}
            />
          </div>
          <div>
            <TwLableForm>BUSINESS NAME</TwLableForm>
            <CustomInput
              label=''
              name='businessName'
              type='text'
              placeholder="Sally's Hair and Nail Salon"
              error={errors.businessName}
              control={control}
              rules={{ required: 'Business name is required' }}
            />
          </div>
          <ButtonIcon
            type='submit'
            disabled={isCreating}
            content={isCreating ? 'Creating...' : 'Create Account'}
            className='w-full bg-light-primary text-white hover:brightness-110'
          />
        </form>
      </div>
    </TwCard>
  )
}

const MerchantCreateSplash: React.FC = () => {
  return (
    <div className='flex flex-col gap-8 px-10'>
      <h1 className='text-2xl font-semibold text-teal-600'>{slides[0].title}</h1>
      <div className='h-[250px] w-full bg-gray-400'></div>
      <div className='text-center'>
        <Spinner className='h-8 w-8' />
        <p>We’re getting your account ready</p>
      </div>
    </div>
  )
}

const MerchantCreateSlide: React.FC<{ onFinish: () => void }> = ({ onFinish }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const prevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1))
  }, [])

  const nextSlide = useCallback(() => {
    if (currentSlide === slides.length - 1) {
      onFinish()
    } else {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1))
    }
  }, [currentSlide, onFinish])

  const goToSlide = useCallback((slide: number) => {
    setCurrentSlide(slide)
  }, [])

  return (
    <div className='layout grid h-[450px] grid-cols-12 gap-6'>
      <div className='col-span-5 bg-gray-300'></div>

      <div className='col-span-7 flex flex-col justify-between bg-white p-4'>
        <div>
          <h1 className='mb-4 text-4xl font-bold text-teal-600'>{slides[currentSlide].title}</h1>

          <div className='flex flex-col'>
            {slides[currentSlide].descriptions.map((it, idx) => (
              <div key={idx}>
                <h3 className='font-bold'>{it.title}</h3>
                <p className='mb-4 text-gray-600'>{it.content}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <button
            onClick={prevSlide}
            className={cn('invisible', currentSlide && 'visible')}>
            Back
          </button>

          <div className='flex w-full justify-center gap-2'>
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`h-3 w-3 rounded-full ${currentSlide === index ? 'bg-teal-600' : 'bg-gray-300'}`}></button>
            ))}
          </div>

          <button onClick={nextSlide}>
            {currentSlide === slides.length - 1 ? 'Finish' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  )
}

const slides = [
  {
    title: 'Create a rewards program that will keep your customers coming back for more',
    descriptions: [
      {
        title: 'Create a reward program that suits you',
        content:
          'Whether it’s ‘buy X, get Y free’ or a points-rewards program, we’ve got you covered. You select the loyalty program that best suits you and your customers.',
      },
      {
        title: 'Set up and start reclaiming in minutes',
        content:
          'No need for tablets, complex POS setups or merchant terminals. Setup within minutes, knowing that zero trees were killed in the process.',
      },
      {
        title: 'Give your customer’s want they want',
        content: '1 in 2 customers want to use in-store loyalty programs through their phones',
      },
    ],
  },
  {
    title: 'Create special offers for your customers to reclaim',
    descriptions: [
      {
        title: 'Promote your latest products and services',
        content: "Try different offers and see which offers your customer's like more.",
      },
      {
        title: 'Build better relationships with your customers',
        content:
          'Gain better insights about your customers to drive repeat visits, increase engagement and enhance customer satisfaction.',
      },
      {
        title: 'XXX',
        content: '1 in 2 customers want to use in-store loyalty programs through their phones.',
      },
    ],
  },
]

export default MerchantCreate
