import { useUserTeamsStore } from 'UseMerchantStore'
import clsx from 'clsx'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import { cn } from 'components/common/utils/tailwindUtils'
import { MENU_BOTTOM_TYPE } from 'constants/common'
import { PATH } from 'constants/routes'
import { ReactComponent as HistorySVG } from 'icons/history.svg'
import { ReactComponent as HomeSVG } from 'icons/home.svg'
import { ReactComponent as NotificationSVG } from 'icons/notification.svg'
import { ReactComponent as QRCodeSVG } from 'icons/qr_code.svg'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { useMenuBottomType } from 'stores/menuBottomType'
import tw from 'twin.macro'

export const MbStickyFooter: React.FC<{ className?: string }> = ({ className }) => {
  const location = useLocation()
  const firebase = useFirebase(firebaseConfig)
  const { user } = useUserNullable({ auth: firebase.auth })
  const userTeams = useUserTeamsStore(user, firebase.firebaseDb)
  const isMerchant = userTeams.type === 'SUCCESS' ? userTeams.teams?.permissions : undefined
  const isHomePage = useMemo(
    () => location.pathname === '/' || location.pathname.split('/').includes('admin'),
    [location.pathname],
  )
  const isAdminScreen = location.pathname.includes('admin')
  const { menuBottomType, setMenuBottomType } = useMenuBottomType()

  const onClickGotoMerchant = () => {
    if (!isMerchant) return navigate(PATH.MERCHANT_CREATE)

    return isMerchant ?
        navigate(PATH.MERCHANT_DASHBOARD.replace(':id', Object.keys(isMerchant)[0]))
      : null
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === PATH.HOME) {
      setMenuBottomType(MENU_BOTTOM_TYPE.HOME)
    }
  }, [location.pathname, setMenuBottomType])

  if (!user) {
    return <></>
  }

  return (
    <aside
      className={cn(
        `bottom-[-1px] z-20 w-full divide-y rounded-tl-3xl rounded-tr-3xl bg-light-mb-main shadow-top
        lg:hidden ${isHomePage ? 'block' : 'hidden'}`,
        className,
      )}>
      <div className='flex py-1 text-cxs md:text-xs'>
        {isAdminScreen && (
          <>
            <TwBtmMenuItem
              onClick={() => {
                onClickGotoMerchant()
                setMenuBottomType(MENU_BOTTOM_TYPE.HOME)
              }}
              className={clsx({
                '!text-black': menuBottomType === MENU_BOTTOM_TYPE.HOME,
              })}>
              <HomeSVG className='h-5 w-5' />
              <TwBtmMenuText>Home</TwBtmMenuText>
            </TwBtmMenuItem>
            <TwBtmMenuItem
              onClick={() => {
                onClickGotoMerchant()
                setMenuBottomType(MENU_BOTTOM_TYPE.HISTORY)
              }}
              className={clsx({
                '!text-black': menuBottomType === MENU_BOTTOM_TYPE.HISTORY,
              })}>
              <HistorySVG className='h-5 w-5' />
              <TwBtmMenuText>My History</TwBtmMenuText>
            </TwBtmMenuItem>
          </>
        )}

        {isAdminScreen && (
          <TwBtmMenuItem
            onClick={() => {
              onClickGotoMerchant()
              setMenuBottomType(MENU_BOTTOM_TYPE.NOTIFICATION)
            }}
            className={clsx({
              '!text-black': menuBottomType === MENU_BOTTOM_TYPE.NOTIFICATION,
            })}>
            <NotificationSVG className='h-5 w-5' />
            <TwBtmMenuText>Notification</TwBtmMenuText>
          </TwBtmMenuItem>
        )}
        {!isAdminScreen && (
          <TwBtmMenuItem
            onClick={() => {
              navigate(PATH.HOME)
            }}
            className={clsx({ '!text-black': location.pathname === PATH.HOME })}>
            <HomeSVG className='h-5 w-5' />
            <TwBtmMenuText>My Cards</TwBtmMenuText>
          </TwBtmMenuItem>
        )}
        {isAdminScreen && (
          <TwBtmMenuItem
            onClick={() => {
              onClickGotoMerchant()
              setMenuBottomType(MENU_BOTTOM_TYPE.QR_CODE)
            }}
            className={clsx({ '!text-black': menuBottomType === MENU_BOTTOM_TYPE.QR_CODE })}>
            <QRCodeSVG className='h-5 w-5' />
            <TwBtmMenuText>QR Code</TwBtmMenuText>
          </TwBtmMenuItem>
        )}
      </div>
    </aside>
  )
}
export default MbStickyFooter
export const TwBtmMenuItem = tw.div`py-1.5 gap-1 text-light-disabled md:py-3 flex cursor-pointer drop-shadow-2xl flex-1 flex-col place-content-center items-center`
export const TwBtmMenuText = tw.div`whitespace-nowrap text-xs font-medium`
